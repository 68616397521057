<template>
  <div>
    <a-upload v-show="!fileList[0]?.uid"
              :file-list="fileList"
              :show-upload-list="false"
              accept=".MP4,.mp4"
              :before-upload="beforeUpload"
              :customRequest="uploadCustomer"
              @change="handleChange">

      <div class="flex flex-col justify-center items-center text-color-999 bg-color-f5 cursor-pointer border border-radius"
           style="width: 320px; height: 180px;">
        <svg-icon class="text-28"
                  type="icontianjia1" />
        <span class="inline-block mt-16">上传视频</span>
      </div>
    </a-upload>

    <p class="mt-8 text-12 text-color-999">限MP4格式视频，大小不超过50M。</p>

    <!-- <a-upload v-show="fileList[0]?.uid"
              class="absolute -right-64 bottom-24"
              :file-list="fileList"
              :show-upload-list="false"
              accept=".MP4,.mp4"
              :before-upload="beforeUpload"
              :customRequest="uploadCustomer"
              @change="$emit('update:data', $event.fileList)">
      <p class="ml-12 w-max text-admin-primary cursor-pointer">重新选择</p>
    </a-upload> -->
  </div>

</template>

<script>
import _ from "lodash";

import SvgIcon from "@/components/SvgIcon";

import liveApi from "@/service/api/live";

export default {
  name: "UploadVideo",

  components: {
    SvgIcon,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:data"],

  data() {
    return {
      fileList: [],
    };
  },
  watch: {
    data: {
      handler(value) {
        this.fileList = value;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},

  methods: {
    beforeUpload(file) {
      const MB = 1024 * 1024;

      const nameList = _(file.name).toLower().split(".");
      const type = _.last(nameList);

      if (type !== "mp4") {
        this.$message.error("只能上传MP4格式视频");
        return false;
      }

      const isLt50M = file.size / MB < 50;

      if (!isLt50M) {
        this.$message.error("上传视频需要小于50MB");
        return false;
      }

      this.fileList = [];
      return true;
    },

    async uploadCustomer(file) {
      const formData = new FormData();
      formData.append("file", file.file);

      try {
        const res = await liveApi.uploadTrailer(formData);
        file.onSuccess(res);
      } catch (error) {
        file.onError();
      }
    },

    handleChange({ file, fileList }) {
      if (!file.status) {
        return;
      }

      this.$emit("update:data", fileList);
    },
  },
};
</script>
<style  scoped>
</style>